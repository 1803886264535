import { apiWrapperSetup } from '@applandeo/api-wrapper';
import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import GlobalSuspense from 'components/Global/GlobalSuspense/GlobalSuspense';
import I18NLayer from 'components/Global/I18N';
import Routing from 'pages/Routing/Routing';
import React, { memo, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { installSW } from 'serviceWorker/swSetup';

import { ModalProvider } from '@/components/common/Modal/ModalProvider';
import AlertMessagesProvider from '@/components/Global/AlertMessages/AlertMessagesProvider';
import { ConfirmationDialogProvider } from '@/components/Global/ConfirmationDialog/ConfirmationDialogProvider';
import ErrorWrapper from '@/components/Global/ErrorWrapper/ErrorWrapper';
import GlobalDataManagement from '@/components/Global/GlobalDataManagement/GlobalDataManagement';
import GlobalStyle from '@/components/Global/GlobalStyling';
import { initializeMixpanel } from '@/mixPanel/mixPanelActions';
import initializeSentry from '@/sentry/initializeSentry';
import { unregister } from '@/utils/interceptor/interceptor';

import { materialUITheme } from './materialUI';

const InitializeApplication = () => {
    useEffect(() => {
        installSW();
        initializeSentry();
        initializeMixpanel();
        apiWrapperSetup({
            commonLocalErrorHandler: error => {
                console.log('Common: On Local error', error);
            },
            commonGlobalErrorHandler: error => {
                console.log('Common: On Global error', error);
            },
        });
        return () => {
            unregister();
        };
    }, []);

    return (
        <React.StrictMode>
            <ErrorWrapper>
                <Sentry.ErrorBoundary>
                    <GlobalStyle />
                    <ThemeProvider theme={materialUITheme}>
                        <GlobalSuspense>
                            <Router basename={process.env.PUBLIC_URL}>
                                <I18NLayer>
                                    <AlertMessagesProvider>
                                        <GlobalDataManagement>
                                            <ModalProvider>
                                                <ConfirmationDialogProvider>
                                                    <Routing />
                                                </ConfirmationDialogProvider>
                                            </ModalProvider>
                                        </GlobalDataManagement>
                                    </AlertMessagesProvider>
                                </I18NLayer>
                            </Router>
                        </GlobalSuspense>
                    </ThemeProvider>
                </Sentry.ErrorBoundary>
            </ErrorWrapper>
        </React.StrictMode>
    );
};

const Memoized = memo(InitializeApplication);
export default Memoized;
