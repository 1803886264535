import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ALERT_VARIANT, useAlertMessages } from '@/components/Global/AlertMessages/AlertMessagesProvider';

/**
 * useNetworkInfo can be extended by adding other properties to state object
 * @returns isOffline
 */
export const useNetworkInfo = () => {
    const [networkState, setNetworkState] = useState({
        isOffline: !navigator.onLine,
    });

    useEffect(() => {
        const updateNetState = () => {
            const isOffline = !navigator.onLine;
            setNetworkState({ isOffline });
        };

        window.addEventListener('online', updateNetState);
        window.addEventListener('offline', updateNetState);

        return () => {
            window.removeEventListener('online', updateNetState);
            window.removeEventListener('offline', updateNetState);
        };
    }, []);

    return networkState;
};

const ALERT_KEY = 'Offline allert';

export const useOfflineAlert = () => {
    const { t } = useTranslation();
    const { createAlert, removeAlert } = useAlertMessages();
    const { isOffline } = useNetworkInfo();

    // State is only for not calling "removeAlert" unnecessarily
    const [isAlertCreated, setIsAlertCreated] = useState(isOffline);

    useEffect(() => {
        if (isOffline && !isAlertCreated) {
            createAlert(ALERT_VARIANT.warning, t('error_noInternetConnection_message'), {
                persist: true,
                key: ALERT_KEY,
            });
            setIsAlertCreated(true);
        }
        if (!isOffline && isAlertCreated) {
            removeAlert(ALERT_KEY);
            setIsAlertCreated(false);
        }
    }, [isOffline, isAlertCreated]);
};
